import React from "react"

import ChecklistItem from "./ChecklistItem"
import Container from "./Container"

const Checklist = ({ title, text, items, small = false }) => (
	<Container>
		<h1 className="text-center mb-20 display1 block">{title}</h1>
		<p className="text-center mb-60 text-base block mx-auto w-2/3 xl:w-2/5">{text}</p>
		<ul className={`grid md:grid-cols-12 md:gap-x-40 gap-y-20`}>
			{items.map((element, index) => (
				<li
					key={`checklistitem-${index}`}
					className={`col-span-12 px-12 py-16 rounded-large bg-white ${
						small
							? "col-span-8 col-start-3"
							: "md:col-span-6 lg:col-span-5 odd:lg:col-start-2 even:lg:col-start-7"
					}`}>
					<ChecklistItem label={element} />
				</li>
			))}
		</ul>
	</Container>
)

export default Checklist
