import React, { Suspense } from "react"
import "../styles/plyr/plyr.scss"

const Plyr = React.lazy(() => import("plyr-react"))

const VideoPlayer = props => {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Plyr {...props} />
		</Suspense>
	)
}

export default VideoPlayer
