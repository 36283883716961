import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
// import { BgImage } from "gbimage-bridge"

import Grid from "./Grid"
import Stage from "./Stage"

const ProductHero = ({ title, subtitle, image, children }) => (
	<div className="pb-40 md:pb-0 lg:pb-60">
		<Stage name="hero" className="relative">
			<div className="pt-[60px] md:pt-[80px] md:min-h-[510px] xl:min-h-[570px] flex flex-col justify-start md:justify-center">
				<Grid>
					<div className="col-start-1 xl:col-start-2 col-span-full md:col-span-5 xl:col-span-4 flex flex-col justify-center">
						<div className="mt-spacer-xl md:mt-0 lg-mt-[80px] mb-[50px] md:mb-0 z-10">
							<h1 className="text-white display1">{title}</h1>
							{subtitle && (
								<h2 className="font-sans font-normal text-base mt-[14px] lg:mr-50 text-white opacity-90 text-opacity-90 leading-28">
									{subtitle}
								</h2>
							)}
						</div>
					</div>
					<div className="col-start-1 col-span-full md:col-start-6 md:col-span-7 flex flex-col justify-center lg:mt-60 -mb-40 md:mb-0 lg:-mb-60">
						<div className="bg-porcelain lg:h-[430px] xl:h-[490px] rounded-medium overflow-hidden">
							<GatsbyImage
								image={getImage(image.localFile)}
								alt={image.alternativeText}
								title={title}
								className="h-full w-full rounded-medium overflow-hidden"
								imgClassName="rounded-medium overflow-hidden"
								objectFit="cover"
								objectPosition="center center"
							/>
						</div>
					</div>
				</Grid>
			</div>
		</Stage>
	</div>
)

export default ProductHero
