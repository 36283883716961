import React from "react"

import { Check } from "./Icon/"

const ChecklistItem = ({ label, className, to = null }) => (
	<span
		className={`group flex flex-column justify-left items-center ${className || ""} ${
			to !== null
				? "transition duration-500 ease-in-out transform hover:scale-105 shadow-none hover:shadow-md"
				: ""
		}`}>
		<span className="flex-none">
			<Check
				className={`text-pictonblue ${to !== null ? "group-hover:text-atlantis" : ""}`}
			/>
		</span>
		<span className="ml-12">{label}</span>
	</span>
)

export default ChecklistItem
