import React from "react"
import { LazyMotion, domAnimation, m } from "framer-motion"

const Backdrop = ({ children, onClick }) => {
	return (
		<LazyMotion features={domAnimation}>
			<m.div
				onClick={onClick}
				className="fixed z-50 top-0 left-0 right-0 bottom-0 w-screen h-screen bg-[#000000e1] flex items-center justify-center"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}>
				{children}
			</m.div>
		</LazyMotion>
	)
}

export default Backdrop
