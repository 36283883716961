import React, { useState } from "react"
import { graphql } from "gatsby"

import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import Grid from "../../components/Grid"

import VideoPlayer from "../../components/VideoPlayer"

import { BlueBanner } from "../../components/Banner"
import Checklist from "../../components/Checklist"
import Icon from "../../components/Icon/Argument"

import ProductHero from "../../components/ProductHero"
import BlockMobileApp from "../../components/Blocks/BlockMobileApp"

import Modal from "../../components/Modal"

import useSiteMetadata from "../../hooks/useSiteMetadata"

import { AnimatePresence } from "framer-motion"

const BlockText = ({ className, data: { title, text, striping } }) => {
	return (
		<div className={`group w-full ${className} ${striping ? "enableStriping" : ""}`}>
			<Grid
				className=""
				containerClassName={`${striping ? "py-spacer-xl" : "mt-spacer-xl mb-spacer-xl"}`}>
				<div className="col-start-1 col-end-13 md:col-start-2 md:col-end-12 xl:col-start-3 xl:col-span-8">
					{title && <h2 className="mb-20 display2">{title}</h2>}
					<p className="text-jetblack">
						{text.split("\n").map((item, key) => {
							return (
								<React.Fragment key={key}>
									{item}
									<br />
								</React.Fragment>
							)
						})}
					</p>
				</div>
			</Grid>
		</div>
	)
}

const BlockVideo = ({ className, data: { title, video, posterImage } }) => {
	// ${ striping ? "bg-porcelain rounded-small py-spacer-xl" : "mb-spacer-xl" }

	const [modalOpen, setModalOpen] = useState(false)

	const close = () => setModalOpen(false)
	const open = () => setModalOpen(true)

	return (
		<AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
			<Grid containerClassName={`my-spacer-xl ${className}`}>
				<div className="col-start-1 col-end-13 md:col-start-2 md:col-end-12 xl:col-start-3 xl:col-span-8 overflow-hidden">
					{/* Video Player Content  */}

					<div className="relative w-full aspect-w-16 aspect-h-9 rounded-large overflow-hidden bg-tealblue group">
						{modalOpen && (
							<Modal modalOpen={modalOpen} handleClose={close}>
								<VideoPlayer
									autoPlay={true}
									source={{
										type: "video",
										sources: [{ src: video.localFile.url, provider: "html5" }],
									}}
								/>
							</Modal>
						)}

						<div className="flex flex-col w-full items-center justify-center absolute top-0 left-0 right-0 bottom-0 z-30 transform transform-bg-opacity duration-200 rounded-large overflow-hidden">
							<svg
								viewBox="0 0 100 100"
								onClick={() => (modalOpen ? close() : open())}
								xmlns="http://www.w3.org/2000/svg"
								className="cursor-pointer text-white opacity-80 group-hover:opacity-100 fill-current 
										w-[75px] h-[75px] lg:w-[100px] lg:h-[100px] transition duration-200 group-hover:scale-[1.1]">
								<path d="M50 100C22.386 100 0 77.614 0 50S22.386 0 50 0s50 22.386 50 50-22.386 50-50 50zm-9.273-68.33a1.206 1.206 0 00-.648-.189 1.18 1.18 0 00-1.19 1.169v34.7c0 .226.066.447.192.636a1.204 1.204 0 001.646.344l27.249-17.35a1.155 1.155 0 000-1.96l-27.25-17.35z" />
							</svg>
							{title && (
								<h3 className="absolute bottom-0 display3 text-white text-md lg:text-lg xl:text-xl leading-34 mb-spacer-sm font-bold">
									{title}
								</h3>
							)}
						</div>

						<div className="rounded-large overflow-hidden">
							<GatsbyImage
								alt={posterImage.alternativeText}
								image={getImage(posterImage.localFile)}
								className="transition duration-200 group-hover:opacity-90 object-cover rounded-large overflow-hidden"
								imgClassName="rounded-large overflow-hidden"
								objectPosition="center"
							/>
						</div>
					</div>

					{/* End: Video Player Content  */}
				</div>
			</Grid>
		</AnimatePresence>
	)
}

const BlockVisualization = ({ data, className }) => {
	if (data.verticalAlignment) {
		return (
			<div className={`group w-full ${className} ${data.striping ? "enableStriping" : ""}`}>
				<Grid
					containerClassName={`relative overflow-hidden`}
					className="pt-spacer-xl -mb-[20px] lg:-mb-[50px] xl:-mb-[70px]">
					<div className="col-start-1 col-end-13 md:col-start-2 md:col-end-12 xl:col-start-4 xl:col-end-10">
						<h2 className="display2 font-headline font-lg text-jetblack text-center">
							{data.title}
						</h2>
						<p className="mt-20 text-jetblack text-center">{data.text}</p>
					</div>
					{data.image && (
						<>
							<div className="mt-spacer-md col-start-1 col-end-13 md:col-start-2 md:col-end-12 xl:col-start-3 xl:col-end-11 ">
								{/* max-h-[160px] md:max-h-[272px] lg:max-h-[363px] xl:max-h-[414px] */}
								<div className="w-full">
									<GatsbyImage
										alt={data.image.alternativeText}
										image={getImage(data.image.localFile)}
										className={data.imageCastsShadow ? "shadow-screenshot" : ""}
										objectPosition="top"
									/>
								</div>
							</div>
							<div className="absolute left-0 right-0 bottom-0 bg-gradient-to-t from-[#F2F4F5] to-transparent h-[32px]"></div>
						</>
					)}
				</Grid>
			</div>
		)
	} else {
		return (
			<div className={`group w-full ${className} ${data.striping ? "enableStriping" : ""}`}>
				<Grid
					containerClassName={`group`}
					className="py-spacer-xl group-odd-last:pb-0 items-center sm:gap-y-[40px] md:gap-y-[50px] lg:gap-y-0">
					<div
						className="
							col-start-1 col-end-13 md:col-start-2 md:col-end-11 order-first lg:order-none

							group-odd:lg:col-start-2 group-odd:lg:col-span-5
							group-odd:xl:col-start-2 group-odd:xl:col-span-4

							group-even:lg:col-start-7 group-even:lg:col-span-5
							group-even:xl:col-start-8 group-even:xl:col-span-4
						">
						<h2 className="display2 font-headline font-lg text-jetblack">
							{data.title}
						</h2>

						<p className="mt-20 text-jetblack">
							{data.text.split("\n").map((item, key) => {
								return (
									<React.Fragment key={key}>
										{item}
										<br />
									</React.Fragment>
								)
							})}
						</p>
					</div>
					<div className="col-start-1 col-end-13 md:col-start-2 md:col-end-11 group-odd:lg:col-end-13 group-odd:lg:col-span-6 group-even:lg:col-span-6 group-even:lg:col-start-1 group-even:order-first">
						{/* max-h-[188px] md:max-h-[328px] lg:max-h-[250px] xl:max-h-[366px] */}
						<div className="w-full h-full flex flex-row justify-center group-odd:lg:justify-start group-even:lg:justify-end">
							<GatsbyImage
								alt={data.image.alternativeText}
								image={getImage(data.image.localFile)}
								className={` ${data.imageCastsShadow ? "shadow-screenshot" : ""}`}
							/>
						</div>
					</div>
				</Grid>
			</div>
		)
	}
}

const BlockArguments = ({ className, data: { icons } }) => {
	const colors = ["text-pictonblue", "text-solaris", "text-atlantis", "text-tealblue"]
	var iconSpan = ""
	switch (icons.length) {
		case 2:
			iconSpan = "xl:col-span-6"
			break
		case 3:
			iconSpan = "xl:col-span-4"
			break
		default:
		case 4:
			iconSpan = "xl:col-span-3"
			break
	}
	return (
		<Grid className={`mb-spacer-xl gap-y-60`} containerClassName={className}>
			{icons.map((icon, index) => (
				<div
					key={`icon-${index}`}
					className={`
						col-span-12
						md:col-span-6
						${iconSpan}
					`}>
					<div key={`icon-${index}`} className="flex flex-col items-center">
						<div className={colors[index % colors.length]}>
							<Icon className="h-[52px]" type={icon.icon} />
						</div>
						<h3 className="max-w-[285px] mt-30 display3 font-headline font-md text-jetblack text-center">
							{icon.title}
						</h3>
						<p className="max-w-[285px] mt-12 text-jetblack text-center">{icon.text}</p>
					</div>
				</div>
			))}
		</Grid>
	)
}

const componentsMap = {
	STRAPI__COMPONENT_PRODUCT_TEXT: BlockText,
	STRAPI__COMPONENT_PRODUCT_VISUALIZATION: BlockVisualization,
	STRAPI__COMPONENT_PRODUCT_MOBILE_APP: BlockMobileApp,
	STRAPI__COMPONENT_PRODUCT_ARGUMENTS: BlockArguments,
	STRAPI__COMPONENT_PRODUCT_VIDEO: BlockVideo,
}

const Block = ({ block, className }) => {
	const Component = componentsMap[block.__typename]

	if (!Component) {
		return null
	}

	return <Component data={block} className={className} />
}

const ProductBlockRenderer = ({ blocks }) => {
	return (
		<>
			{blocks.map((block, index) => (
				<Block key={`${index}${block.__typename}`} block={block} className="productBlock" />
			))}
		</>
	)
}

const ProductPage = ({ data: { product } }) => {
	var factsheetDownload = null

	if (product.factsheet?.localFile?.publicURL) {
		factsheetDownload = {
			secondaryCallToAction: "Get fact sheet",
			secondaryCallToActionUrl: product.factsheet?.localFile?.publicURL,
			secondaryCallToActionEvent: `download-product-factsheet-${product.seo.title}`,
		}
	}

	const { siteUrl } = useSiteMetadata()

	return (
		<Layout translucent={true}>
			<Seo
				title={product.seo.title}
				description={product.seo.description}
				keywords={product.seo.keywords}
				ogImage={product.hero.ogImage.localFile}
			/>

			<ProductHero
				title={product.hero.title}
				subtitle={product.hero.description}
				image={product.hero.image}
			/>

			<div className="">
				<ProductBlockRenderer blocks={product.body} />
			</div>

			{/* {product.certificates.length > 0 && (
				<>
					<Container className="mt-spacer-xl pb-spacer-md">
						<h1 className="display1">{product.hero.title} is certified</h1>
					</Container>
					<Grid className="gap-20 md:gap-40">
						{product.certificates.map(({ type, authority, logo, document }, index) => (
							<div
								className={`col-span-12 md:col-span-6 xl:col-span-4
						 bg-porcelain rounded-medium px-40 py-60 flex flex-col items-center`}
								key={`authority-${index}`}>
								<img
									src={logo?.localFile.publicURL}
									title={logo?.alternativeText}
									alt={logo?.alternativeText}
									className="mx-auto h-[80px]"
									height="80px"
								/>
								<div className="bodyp my-30">{type}</div>
								<a href={document?.localFile?.publicURL} download>
									<Button primary={true} label="Download Certificate" />
								</a>
							</div>
						))}
					</Grid>
				</>
			)} */}

			{product.features && product.features.length > 0 && (
				<div className="bg-porcelain py-spacer-xl">
					<Checklist
						title={`Key features`}
						items={product.features.map(feature => feature.description)}
						small={product.features.length < 6}
					/>
				</div>
			)}

			<BlueBanner
				className="my-spacer-xl"
				title={product.banner.title}
				subtitle={product.banner.pretext}
				image={
					product.banner.screenshot ? getImage(product.banner.screenshot.localFile) : null
				}
				callToAction="Schedule a demo"
				callToActionUrl="/demo"
				{...factsheetDownload}
			/>
		</Layout>
	)
}

export default ProductPage

export const query = graphql`
	query ($id: String) {
		product: strapiProduct(id: { eq: $id }) {
			slug

			description {
				data {
					childMdx {
						excerpt(pruneLength: 160, truncate: true)
					}
				}
			}

			seo {
				title
				description
				keywords
			}

			certificates {
				type
				authority
				logo {
					alternativeText
					localFile {
						publicURL
					}
				}
				document {
					alternativeText
					localFile {
						publicURL
					}
				}
			}

			hero {
				title
				description
				image {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 765, height: 493)
						}
					}
				}
				ogImage: image {
					localFile {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED
								width: 1200
								height: 630
								formats: [JPG]
								transformOptions: { fit: COVER }
							)
						}
					}
				}
			}

			factsheet {
				alternativeText
				localFile {
					publicURL
				}
			}

			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}

			features {
				description
			}

			body {
				__typename

				... on STRAPI__COMPONENT_PRODUCT_TEXT {
					id
					strapi_component
					title
					text
					striping
				}

				... on STRAPI__COMPONENT_PRODUCT_VIDEO {
					id
					strapi_component
					title
					posterImage {
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(layout: CONSTRAINED, width: 880)
							}
						}
					}
					video {
						localFile {
							publicURL
							url
						}
					}
				}

				... on STRAPI__COMPONENT_PRODUCT_VISUALIZATION {
					id
					strapi_component
					title
					text
					striping
					verticalAlignment
					image {
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(layout: CONSTRAINED, width: 880)
							}
						}
					}
					imageCastsShadow
				}

				... on STRAPI__COMPONENT_PRODUCT_MOBILE_APP {
					id
					strapi_component
					title
					description
					striping
					appleAppStoreAppUrl
					googlePlayStoreAppUrl
					windowsAppsStoreUrl
					image {
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(layout: CONSTRAINED, width: 550)
							}
						}
					}
					imageAlignment
				}

				... on STRAPI__COMPONENT_PRODUCT_ARGUMENTS {
					id
					strapi_component
					icons {
						title
						text
						icon
					}
				}
			}
		}
	}
`
