import React from "react"
import ReactDOM from "react-dom"
import { LazyMotion, domAnimation, m } from "framer-motion"

import Backdrop from "./Backdrop"

const dropIn = {
	hidden: {
		y: "300px",
		opacity: 0,
	},
	visible: {
		y: "0",
		opacity: 1,
		transition: {
			duration: 0.5,
			type: "easeIn",
		},
	},
	exit: {
		y: "-300px",
		opacity: 0,
	},
}

const Modal = ({ modalOpen, handleClose, rootElement = "modal-root", children }) => {
	if (!modalOpen) return null

	return ReactDOM.createPortal(
		<LazyMotion features={domAnimation}>
			<Backdrop onClick={handleClose}>
				<m.div
					onClick={e => e.stopPropagation()}
					className="w-4/6"
					variants={dropIn}
					initial="hidden"
					animate="visible"
					exit="exit">
					<div
						className="flex justify-end text-white p-18 hover:text-pictonblue cursor-pointer"
						onClick={handleClose}>
						<svg
							className="fill-current"
							width="18"
							height="18"
							xmlns="http://www.w3.org/2000/svg">
							<path d="M10.429 9L18 1.429 16.571 0 9 7.571 1.429 0 0 1.429 7.571 9 0 16.571 1.429 18 9 10.429 16.571 18 18 16.571z" />
						</svg>
					</div>
					<div className="mb-[54px]">{children}</div>
				</m.div>
			</Backdrop>
		</LazyMotion>,
		document.getElementById(rootElement),
	)
}

export default Modal

// export default class Modal extends React.Component {
// 	onClose = e => {
// 		if (!e.target.classList.contains("modal-content")) {
// 			this.props.onClose && this.props.onClose(e)
// 		}
// 	}

// 	render() {
// 		if (!this.props.show) {
// 			return null
// 		}
// 		return (
// 			<div className="z-50 fixed top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center h-screen w-screen bg-black bg-opacity-90 p-24">
// 				<div className="w-3/4">
// 					<div className="flex justify-end p-18" onClick={this.onClose}>
// 						<span className="text-white hover:text-pictonblue cursor-pointer">
// 							<svg
// 								className="fill-current"
// 								width="18"
// 								height="18"
// 								xmlns="http://www.w3.org/2000/svg">
// 								<path d="M10.429 9L18 1.429 16.571 0 9 7.571 1.429 0 0 1.429 7.571 9 0 16.571 1.429 18 9 10.429 16.571 18 18 16.571z" />
// 							</svg>
// 						</span>
// 					</div>
// 					<div className="aspect-video">{this.props.children}</div>
// 				</div>
// 			</div>
// 		)
// 	}
// }
